<template>
  <div class="events">
    <EventCard v-for="event in events" :key="event.id" :event="event" /> <!--③実際にコンポーネント使う-->
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from '@/components/EventCard.vue' //①from 〇〇 import コンポーネント名
import EventService from '@/services/EventService.js'

export default {
  name: 'EventList',
  components: {
    EventCard //②コンポーネント登録
  },
  data(){
    return {
      events: null,
    }
  },
  created(){
    EventService.getEvents()
      .then(response => {
        this.events = response.data
      })
      .catch(error => {
        console.log(error)
      })
  }
}
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>