import axios from 'axios'

const apiClient3 = axios.create({
    baseURL: 'https://20m1vm8dwc.execute-api.us-east-1.amazonaws.com',
    withCredentials:false,
    'responseType': 'blob',
    //headers: {
    //    'Content-Type':'application/json',
    //}
})

export default {
    get_gif(){
        return apiClient3.get('/gif')
    },
}