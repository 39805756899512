import axios from 'axios'

const apiClient2 = axios.create({
    baseURL: 'https://20m1vm8dwc.execute-api.us-east-1.amazonaws.com',
    withCredentials:false,
    headers: {
        Accept: 'application/json',
        'Content-Type':'application/json',
    }
})

export default {
    get_fastapi(){
        return apiClient2.get('/hello')
    },
}