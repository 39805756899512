<template>
  <div class="about">
    <img src="https://serverlessfastapibucket.s3.amazonaws.com/animation.gif">
    <img src="https://serverlessfastapibucket.s3.amazonaws.com/animation.gif" crossorigin="anonymous">
    <h1>{{hello}}</h1>
    <img :src="url">
  </div>
</template>

<script>
import fastapi from '@/services/fastapi.js'
import fastapi_gif from '@/services/fastapi_gif.js'
//import fastapi_gif_copy from '@/services/fastapi_gif_copy.js'

export default {
  data(){
    return {
      hello: null,
      url:null,
    }
  },
  created(){
    fastapi_gif.get_gif()
      .then(response => {
        let blob = response.data;
        //blob.crossOrigin = "use-credentials";
        let url_base = window.URL || window.webkitURL
        this.url = url_base.createObjectURL(blob)
      })
      .catch(error => {
        console.log(error)
      });
    
    fastapi.get_fastapi()
      .then(response =>{
        this.hello = response.data;
      })
  }
}
</script>

